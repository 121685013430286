import { useEffect, useRef, useState } from 'react';

const useWishlistLinkAnimation = wishlistCount => {
  const initialSkipped = useRef(false);

  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    if (!Number.isInteger(wishlistCount) || !initialSkipped.current) {
      initialSkipped.current = true;

      return;
    }

    setShouldAnimate(true);

    const timeout = setTimeout(() => {
      setShouldAnimate(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [wishlistCount]);

  return shouldAnimate;
};

export default useWishlistLinkAnimation;
