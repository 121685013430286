import React, { useCallback, useMemo } from 'react';
import { array, bool, func, number, shape, string } from 'prop-types';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';

import appSettings from '../../../config/settings';
import { useConfiguration } from '../../../context/configurationContext';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { spxCurlImageURL } from '../../../config/configBranding';

import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../util/search';
import { parse, stringify } from '../../../util/urlHelpers';
import { createResourceLocatorString, matchPathname, pathByRouteName } from '../../../util/routes';
import { propTypes } from '../../../util/types';
import {
  Button,
  LimitedAccessBanner,
  Modal,
  ModalMissingInformation,
  NamedLink,
} from '../../../components';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import TopbarSearchForm from './TopbarSearchForm/TopbarSearchForm';
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu';
import TopbarDesktop from './TopbarDesktop/TopbarDesktop';
import { getCurrentUserWishlist } from '../../../ducks/user.duck';
import bus, { AuthEventTypes } from '../../../modules/bus';

import css from './Topbar.module.css';
import useWishlistLinkAnimation from '../../../hooks/useWishlistLinkAnimation';
import { useSelector } from 'react-redux';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

const redirectToURLWithModalState = (history, location, modalStateParam) => {
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (history, location, modalStateParam) => {
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const isPrimary = o => o.group === 'primary';
const isSecondary = o => o.group === 'secondary';
const compareGroups = (a, b) => {
  const isAHigherGroupThanB = isPrimary(a) && isSecondary(b);
  const isALesserGroupThanB = isSecondary(a) && isPrimary(b);
  // Note: sort order is stable in JS
  return isAHigherGroupThanB ? -1 : isALesserGroupThanB ? 1 : 0;
};
// Returns links in order where primary links are returned first
const sortCustomLinks = customLinks => {
  const links = Array.isArray(customLinks) ? customLinks : [];
  return links.sort(compareGroups);
};

// Resolves in-app links against route configuration
const getResolvedCustomLinks = (customLinks, routeConfiguration) => {
  const links = Array.isArray(customLinks) ? customLinks : [];
  return links.map(linkConfig => {
    const { type, href } = linkConfig;
    const isInternalLink = type === 'internal' || href.charAt(0) === '/';
    if (isInternalLink) {
      // Internal link
      const testURL = new URL('http://my.marketplace.com' + href);
      const matchedRoutes = matchPathname(testURL.pathname, routeConfiguration);
      if (matchedRoutes.length > 0) {
        const found = matchedRoutes[0];
        const to = { search: testURL.search, hash: testURL.hash };
        return {
          ...linkConfig,
          route: {
            name: found.route?.name,
            params: found.params,
            to,
          },
        };
      }
    }
    return linkConfig;
  });
};

const isCMSPage = found =>
  found.route?.name === 'CMSPage' ? `CMSPage:${found.params?.pageId}` : null;
const isInboxPage = found =>
  found.route?.name === 'InboxPage' ? `InboxPage:${found.params?.tab}` : null;
// Find the name of the current route/pathname.
// It's used as handle for currentPage check.
const getResolvedCurrentPage = (location, routeConfiguration) => {
  const matchedRoutes = matchPathname(location.pathname, routeConfiguration);
  if (matchedRoutes.length > 0) {
    const found = matchedRoutes[0];
    const cmsPageName = isCMSPage(found);
    const inboxPageName = isInboxPage(found);
    return cmsPageName ? cmsPageName : inboxPageName ? inboxPageName : `${found.route?.name}`;
  }
};

const GenericError = props => {
  const { show, addRemoveWishlistError } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show || addRemoveWishlistError,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          {show ? (
            <FormattedMessage id="Topbar.genericError" />
          ) : addRemoveWishlistError ? (
            <FormattedMessage id="General.addRemoveWishlistError" />
          ) : null}
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

const Topbar = props => {
  const {
    currentSearchParams,
    history,
    onLogout,
    className,
    rootClassName,
    desktopClassName,
    mobileRootClassName,
    mobileClassName,
    isAuthenticated,
    authScopes,
    authInProgress,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentPage,
    notificationCount,
    location,
    onManageDisableScrolling,
    onResendVerificationEmail,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    showGenericError,
  } = props;

  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();

  const { addRemoveWishlistError } = useSelector(state => state.user);

  const wishlist = getCurrentUserWishlist(currentUser);

  const wishlistCount = wishlist ? Object.values(wishlist).flat().length : 0;

  const shouldAnimateNotification = useWishlistLinkAnimation(
    wishlistCount + (notificationCount || 0)
  );

  const handleMobileMenuOpen = useCallback(() => {
    redirectToURLWithModalState(history, location, 'mobilemenu');
  }, [history, location]);

  const handleMobileMenuClose = useCallback(() => {
    redirectToURLWithoutModalState(history, location, 'mobilemenu');
  }, [history, location]);

  const handleMobileSearchOpen = useCallback(() => {
    redirectToURLWithModalState(history, location, 'mobilesearch');
  }, [history, location]);

  const handleMobileSearchClose = useCallback(() => {
    redirectToURLWithoutModalState(history, location, 'mobilesearch');
  }, [history, location]);

  const handleSubmit = useCallback(
    values => {
      const topbarSearchParams = () => {
        if (isMainSearchTypeKeywords(config)) {
          return { keywords: values?.keywords };
        }
        // topbar search defaults to 'location' search
        const { search, selectedPlace } = values?.location;
        const { origin, bounds } = selectedPlace;
        const originMaybe = isOriginInUse(config) ? { origin } : {};

        return {
          ...originMaybe,
          address: search,
          bounds,
        };
      };
      const searchParams = {
        ...currentSearchParams,
        ...topbarSearchParams(),
      };
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
    },
    [config, currentSearchParams, history, routeConfiguration]
  );

  const handleLogout = useCallback(() => {
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration);

      bus.broadcastEvent(AuthEventTypes.LOGOUT);

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (appSettings.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }, [history, onLogout, routeConfiguration]);

  const { mobilemenu, mobilesearch, keywords, address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Custom links are sorted so that group="primary" are always at the beginning of the list.
  const sortedCustomLinks = sortCustomLinks(config.topbar?.customLinks);
  const customLinks = getResolvedCustomLinks(sortedCustomLinks, routeConfiguration);
  const resolvedCurrentPage = currentPage || getResolvedCurrentPage(location, routeConfiguration);

  const notificationDot =
    notificationCount > 0 || wishlistCount > 0 ? (
      <div
        className={classNames(css.notificationDot, {
          [css.notificationAnimation]: shouldAnimateNotification,
        })}
      />
    ) : null;

  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;
  const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
  const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

  const mobileMenu = (
    <TopbarMobileMenu
      isAuthenticated={isAuthenticated}
      currentUserHasListings={currentUserHasListings}
      currentUser={currentUser}
      onLogout={handleLogout}
      notificationCount={notificationCount}
      currentPage={resolvedCurrentPage}
      customLinks={customLinks}
      wishlistCount={wishlistCount}
    />
  );

  const topbarSearcInitialValues = useCallback(() => {
    if (isMainSearchTypeKeywords(config)) {
      return { keywords };
    }

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = isOriginInUse(config)
      ? address && origin && bounds
      : address && bounds;
    return {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };
  }, [address, bounds, config, keywords, origin]);

  const initialSearchFormValues = useMemo(() => topbarSearcInitialValues(), [
    topbarSearcInitialValues,
  ]);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <NamedLink name="LandingPage" className={css.curlLink}>
        <img
          className={css.curlImage}
          src={spxCurlImageURL}
          alt={intl.formatMessage({ id: 'Topbar.logoIcon' })}
        />
      </NamedLink>
      <LimitedAccessBanner
        isAuthenticated={isAuthenticated}
        authScopes={authScopes}
        currentUser={currentUser}
        onLogout={handleLogout}
        currentPage={resolvedCurrentPage}
      />
      <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
        <Button
          rootClassName={css.searchMenu}
          onClick={handleMobileSearchOpen}
          title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
        >
          <SearchIcon className={css.searchMenuIcon} />
        </Button>

        <Button
          rootClassName={css.menu}
          onClick={handleMobileMenuOpen}
          title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
        >
          <MenuIcon className={css.menuIcon} />
          {notificationDot}
        </Button>
      </div>
      <div className={css.desktop}>
        <TopbarDesktop
          className={desktopClassName}
          currentUserHasListings={currentUserHasListings}
          currentUser={currentUser}
          currentPage={resolvedCurrentPage}
          initialSearchFormValues={initialSearchFormValues}
          intl={intl}
          isAuthenticated={isAuthenticated}
          notificationCount={notificationCount}
          onLogout={handleLogout}
          onSearchSubmit={handleSubmit}
          config={config}
          customLinks={customLinks}
          wishlistCount={wishlistCount}
        />
      </div>
      <Modal
        id="TopbarMobileMenu"
        containerClassName={css.modalContainer}
        isOpen={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {authInProgress ? null : mobileMenu}
      </Modal>
      <Modal
        id="TopbarMobileSearch"
        containerClassName={css.modalContainerSearchForm}
        isOpen={isMobileSearchOpen}
        onClose={handleMobileSearchClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.searchContainer}>
          <TopbarSearchForm
            onSubmit={handleSubmit}
            initialValues={initialSearchFormValues}
            isMobile
            appConfig={config}
          />
          <p className={css.mobileHelp}>
            <FormattedMessage id="Topbar.mobileSearchHelp" />
          </p>
        </div>
      </Modal>
      <ModalMissingInformation
        id="MissingInformationReminder"
        containerClassName={css.missingInformationModal}
        currentUser={currentUser}
        currentUserHasListings={currentUserHasListings}
        currentUserHasOrders={currentUserHasOrders}
        location={location}
        onManageDisableScrolling={onManageDisableScrolling}
        onResendVerificationEmail={onResendVerificationEmail}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
      />

      <GenericError show={showGenericError} addRemoveWishlistError={addRemoveWishlistError} />
    </div>
  );
};

Topbar.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

Topbar.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
};

export default Topbar;
